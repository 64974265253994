body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.student-info-container {
  padding: 12px;
  margin-bottom: 12px;
}

.student-info-container.info-card-number {
  padding: 18px 24px 12px 24px;
  margin: 8px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.student-info-container.info-card-list {
  margin: 8px;
  height: 294px;
  display: flex;
  flex-direction: column;
}

.sticky-column {
  position: sticky;
  left: 0;
  background: white;
  box-shadow: 5px 2px 5px grey;
  border-right: 21px solid #f5f5f5;
}

.section {
  padding: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}

.title-case {
  text-transform: capitalize;
}